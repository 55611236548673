(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$().ready(function() {
	$('body').find(".createAccountForm" ).validate({
		rules: {
			dwfrm_profile_customer_firstname:{
				required:true
			},
			dwfrm_profile_login_password:{
				required:true,
				minlength:8
			},
			dwfrm_profile_login_passwordconfirm:{
				minlength:8,
				equalTo :"#dwfrm_profile_login_password"
			},
			dwfrm_profile_customer_email:{
				required: true,
				email: true
			},
			dwfrm_profile_customer_emailconfirm:{
				required: true,
				email: true,
				equalTo: "#dwfrm_profile_customer_email"
			}
		},
		messages: {
			dwfrm_profile_login_password:{
				required: "",
				minlength: "8 - 20 characters"
			},
			dwfrm_profile_login_passwordconfirm:{
				required: "",
				minlength: "8 - 20 characters",
				equalTo: "Password Does Not Match"
			},
			dwfrm_profile_customer_email:{
				required: "",
				email: "Please Enter Valid Email"
			},
			dwfrm_profile_customer_emailconfirm:{
				required: "",
				email: "Please Enter Valid Email",
				equalTo: "Email addresses do not match."
			}
		},
		errorClass : "errorclient",
	});

	
	$('body').on('click','.contract-service-quote', function(e) {

		if($('form[id="dwfrm_contractservices"]').valid()) {
		var requestForm = $(this).closest('form[id="dwfrm_contractservices"]');
		e.preventDefault();
			var dataInput = requestForm.serialize();
		$.ajax({
		async: false,
		type: 'POST',
		dataType: 'html',
		data: dataInput,
		url: requestForm.attr('action'),
		success: function(resp) {
			console.log('request creation success!');
			console.log(JSON.stringify(resp));
			var response = JSON.parse(resp);
			if(response && response.success) {
				$(".csp-login-wrap").html('').html($(".contract-service-submit-success").html());
			}
		},
		error: function(err){
			console.error('request creation error : '+err)
		}
		});
	}
	});
	$('body').on('input','.phone', function(e) {
	    var phoneValue = $(this).val();
	    var replacedVal = phoneValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
	    var res = !replacedVal[2] ? replacedVal[1] : '(' + replacedVal[1] + ') ' + replacedVal[2] + (replacedVal[3] ? '-' + replacedVal[3] : '');
	    $(this).val(res);
	});
	
	$.validator.addMethod('phone', function (value) {
		$.validator.messages.phone = 'Please enter a valid digit phone number';
		return /^(?:\(([0-9]{3})\)|([0-9]{3}))[\-\.\s]?([0-9]{3})[\-\.\s]?([0-9]{4,35})([0-9]+)?$/.test(jQuery.trim(value));
	}, app.resources["INVALID_PHONE"]);

	$.validator.addMethod('email', function (value) {
		return /^(([^<>()[\]\.,;:~`!#$%^&*+={}'?\/|\\\s@\"]+(\.[^<>()[\]\.,;:~`!#$%^&*+={}'?\/|\\\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:~`!#$%^&*+={}'?\/|\\\s@\"]+\.)+[^<>()[\]\.,;:~`!#$%^&*+={}'?\/|\\\s@\"]{2,})$/i.test(jQuery.trim(value));
	}, app.resources["INVALID_EMAIL"]);

	
});
},{}]},{},[1]);
